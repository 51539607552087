<script setup lang="ts">
  const { t } = useI18n();
</script>

<template>
  <section class="auth-background flex-col flex-jb">
    <common-icon-common-logotype width="42px" height="19px" class="mb-5 lg:mb-0"></common-icon-common-logotype>
    <div class="auth-background--description">
      {{ t('components.auth.background.backgroundText') }}
    </div>
  </section>
</template>

<style scoped lang="scss">
.auth-background {
  @apply bg-black p-[60px];
  &--description {
    @apply text-lg font-medium text-white;
  }
}
</style>
