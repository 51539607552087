<script setup lang="ts">
  const { t } = useI18n();
  const localePath = useLocalePath();

  const route = useRoute();

  const list = {
    recovery: { name: t('components.auth.routing.recovery.title'), page: Page.SIGNUP },
    'recovery-email': { name: t('components.auth.routing.recovery-email.title'), page: Page.SIGNUP },
    'recovery-verify': { name: t('components.auth.routing.recovery-verify.title'), page: Page.SIGNUP },
    'recovery-confirmed': { name: t('components.auth.routing.recovery-confirmed.title'), page: Page.SIGNUP },
    'recovery-expired': { name: t('components.auth.routing.recovery-expired.title'), page: Page.SIGNUP },
    signin: { name: t('components.auth.routing.signin.title'), page: Page.SIGNUP },
    signup: { name: t('components.auth.routing.signup.title'), page: Page.SIGNIN },
    'signup-email': { name: t('components.auth.routing.signup-email.title'), page: Page.SIGNIN },
    'signup-verify': { name: t('components.auth.routing.signup-verify.title'), page: Page.SIGNIN },
    'signup-expired': { name: t('components.auth.routing.signup-expired.title'), page: Page.SIGNIN },
  };

  const currentRoute = computed(() => {
    const name = route.name.split('___')[0] as keyof typeof list

    return list[name]
  });
</script>

<template>
  <section class="auth-routing flex flex-je">
    <nuxt-link :to="localePath(currentRoute?.page)">
      {{ currentRoute?.name }}
    </nuxt-link>
  </section>
</template>

<style scoped lang="scss">
.auth-routing {
  @apply
    absolute top-[60px] right-[60px] text-base font-medium;
}
</style>
